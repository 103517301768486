import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contactForm";
import Hero from "../components/hero";
import Layout from "../components/layout";
import PriceCard from "../components/price-card";
import BackgroundPattern from "../images/Hex bg.svg";

const DataBackupPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      pageData: wpPage(slug: { eq: "data-backup" }) {
        title
        pageFlexibleContent {
          pageFlexibleContent {
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner {
              fieldGroupName
              innerPageBannerHeading
              innerPageBannerSubHeading
              innerPageLink {
                target
                title
                url
              }
              innerPageBannerImage {
                sourceUrl
              }
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_GetInTouch {
              fieldGroupName
              getInTouchHeading
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_BasicContentSection {
              basicContent
              fieldGroupName
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Data Backup",
        item: {
          url: `${siteUrl}/data-backup`,
          id: `${siteUrl}/data-backup`,
        },
      },
    ],
  };

  const {
    pageData: {
      pageFlexibleContent: { pageFlexibleContent },
    },
  } = data;

  const getInTouch = () => {
    let heading = "Get in touch to find out how we can work together";
    if (pageFlexibleContent.length > 0) {
      const reviewSection = pageFlexibleContent.filter(
        (item) =>
          item.fieldGroupName ==
          "Page_Pageflexiblecontent_PageFlexibleContent_GetInTouch"
      );
      if (reviewSection.length > 0) {
        heading = reviewSection[0].getInTouchHeading;
      }
    }
    return (
      <section
        style={{ zIndex: 3 }}
        className="pb-5 py-lg-7 position-relative"
        id="form"
      >
        <Container>
          <Row>
            <Col>
              <h2 className="fs-1 text-center mb-cl-5 mb-4">{heading}</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
    );
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title="Data Backup | Cranfield IT Solutions: Computer Repair & Tech Support Sussex"
        description="Computer Repair & Tech Support."
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/data-backup`,
          title: "Data Backup | Cranfield IT Solutions: Computer Repair & Tech Support Sussex",
          description: "Computer Repair & Tech Support.",
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {pageFlexibleContent.length > 0 &&
          pageFlexibleContent.map((pageInfo, i) => (
            <>
              {pageInfo.fieldGroupName ==
                "Page_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner" && (
                <Hero
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: pageInfo.innerPageBannerHeading,
                      }}
                    ></span>
                  }
                  subHeading={pageInfo.innerPageBannerSubHeading}
                  buttonTarget={pageInfo.innerPageLink?.target}
                  buttonLink={pageInfo.innerPageLink?.url}
                  buttonTitle={pageInfo.innerPageLink?.title}
                  bannerImage={pageInfo.innerPageBannerImage.sourceUrl}
                  hideButton={!pageInfo.innerPageLink ?? true}
                  key={i}
                />
              )}
              {pageInfo.fieldGroupName ==
                "Page_Pageflexiblecontent_PageFlexibleContent_BasicContentSection" && (
                <Container>
                  {" "}
                  <Row>
                    <Col
                      dangerouslySetInnerHTML={{
                        __html: pageInfo.basicContent,
                      }}
                    />
                  </Row>
                </Container>
              )}
            </>
          ))}

        {/* <section className="  position-relative">
					<div
						style={{ zIndex: 1 }}
						className="position-absolute d-none d-lg-block start-50 translate-middle-x top--30 bottom-0"
					>
						<img alt="" className="" src={BackgroundPattern} />
					</div>
					<Container style={{ zIndex: 2 }} className="position-relative">
						<Row className="h-100 g-5 g-lg-2 g-xl-5 align-items-center">
							<PriceCard
								title={
									<>
										<span className="text-primary">Office 365</span> Backup
									</>
								}
								price={
									<>
										<p className="fs-1 pb-0 mb-0">
											<strong className="text-primary roboto-bold ">
												£12.50
											</strong>
											pm <span className="fs-4">+ VAT</span>
										</p>
										<p className="pb-0 fs-3 mb-3">
											per <span className=" roboto-bold ">user</span>
										</p>
									</>
								}
								list={
									<ul className="ps-0">
										<li>Daily Backup of:</li>
										<li>OneDrive</li>
										<li>SharePoint</li>
										<li>Exchange</li>
										<li>Teams</li>
										<li>50GB Per user pooled</li>
									</ul>
								}
								buttonHeight="4rem"
								priceMinHeight="140px"
								priceMinHeightXl="120px"
							/>
							<PriceCard
								title={
									<>
										<span className="text-primary">End User Device </span>{" "}
										Backup
									</>
								}
								price={
									<>
										<p className="fs-1 pb-0 mb-0">
											<strong className="text-primary roboto-bold ">
												£6.50
											</strong>
											pm <span className="fs-4">+ VAT</span>
										</p>
										<p className="pb-0 fs-3 mb-3">
											per <span className=" roboto-bold ">device</span>
										</p>
										<p className="fs-1 pb-0 mb-0">
											<strong className="text-primary roboto-bold ">
												£25.00
											</strong>
											pm <span className="fs-4">+ VAT</span>
										</p>
										<p className="pb-0 fs-3 mb-3">
											for <strong className=" roboto-bold ">250GB</strong> space
										</p>
									</>
								}
								list={
									<ul className="ps-0">
										<li>
											Protection from data loss due to user error, malicious
											attacks, or system failure
										</li>
										<li>Secure backup for all your critical data</li>
										<li>Easily recoverable</li>
										<li>Scheduled regular backups</li>
										<li>Monitoring by our help-desk</li>
										<li>Full protection against any unexpected disruptions</li>
									</ul>
								}
								buttonHeight="4rem"
							/>
							<PriceCard
								title={
									<>
										<span className="text-primary">Quickbooks</span> Backup
									</>
								}
								price={
									<>
										<p className="fs-1 pb-0 mb-0">
											<strong className="text-primary roboto-bold ">
												£12.00
											</strong>
											pm <span className="fs-4">+ VAT</span>
										</p>
										<p className="pb-0 fs-3 mb-3">
											per{" "}
											<strong className=" roboto-bold ">organisation</strong>
										</p>
									</>
								}
								list={
									<ul className="ps-0">
										<li>Backup of your QuickBooks data</li>
										<li>Protection from any unexpected outages</li>
										<li>Stored in a secure, off-site location</li>
										<li>Restore any lost or corrupted information</li>
									</ul>
								}
								buttonHeight="4rem"
								priceMinHeight="200px"
								priceMinHeightXl="120px"
							/>
						</Row>
					</Container>
				</section> */}

        {getInTouch()}
      </Layout>
    </>
  );
};

export default DataBackupPage;
